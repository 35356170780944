<template>
  <div class="qux">
  </div>
</template>
<script>
import Logger from '../core/Logger'
import RestEngine from '../core/RestEngine'
import OpenAIEngine from '../core/OpenAIEngine'
import JSONPath from '../core/JSONPath'
import * as ExportUtil from '../core/ExportUtil'

export default {
  name: 'Logic',
  methods: {

    async initLoadRest() {
      Logger.log(2, "Logic.initLoadRest()", "enter");

      if (this.doNotExecuteScripts) {
        Logger.log(2, "Logic.initLoadRest()", "do not run");
        return
      }
      const widgets = this.getLoadRests()
      for (let i = 0; i < widgets.length; i++) {
        const widget = widgets[i]
        this.executeRest(widget)
      }
      Logger.log(2, "Logic.initLoadRest() > exit", this.dataBindingValues);
    },

    getLoadRests() {
      return Object
        .values(this.model.widgets)
        .filter(w => w.type === 'Rest' && w.props.trigger === 'load')
    },

    async initRepeatRest() {
      Logger.log(2, "Logic.initRepeatRest", "enter");

      if (this.doNotExecuteScripts) {
        Logger.log(2, "Logic.initRepeatRest", "exit > Do not run");
        return
      }
      this._repeatRestIntervals = []
      const widgets = this.getRepeatRests()
      for (let i = 0; i < widgets.length; i++) {
        const widget = widgets[i]
        const id = setInterval(() => {
          this.executeRest(widget)
        }, widget.props.delay * 1000)
        this._repeatRestIntervals.push(id)
      }
      Logger.log(2, "Logic.initRepeatRest", "exit", this.dataBindingValues);

    },


    getRepeatRests() {
      return Object
        .values(this.model.widgets)
        .filter(w => w.type === 'Rest' && w.props.trigger === 'repeat')
    },


    cleanUpRepeatRests() {
      Logger.log(-2, "Logic.cleanUpRepeatRests", "enter");
      if (this._repeatRestIntervals) {
        this._repeatRestIntervals.forEach(id => {
          clearInterval(id)
        })
      }
    },

    async executeAPI(widget, line) {
      Logger.log(-1, 'Luisa.executeOpenAIAssistant() > enter', widget, line)

      //const rest = widget.props.rest
      try {
        // const message = JSONPath.get(this.modelValue, rest.input.databinding)
        // const result = await OpenAIEngine.query(
        //   this.model.id,
        //   this.hash,
        //   rest.token,
        //   rest.assistant,
        //   message
        // )
        // if (result !== undefined) {
        //   if (rest.output.databinding) {
        //     JSONPath.set(this.modelValue, rest.output.databinding, result)
        //   }
        //   this.executeOutGoingLines(widget, true)
        //   return true
        // }
      } catch (e) {
        console.error(e)
        Logger.error("Luisa.executeOpenAIAssistant", "error", e);
      }
      return false
    },

    async executeOpenAIAssistant(widget, line) {
      Logger.log(-1, 'Luisa.executeOpenAIAssistant() > enter', widget, line)

      const rest = widget.props.rest
      try {
        const message = JSONPath.get(this.modelValue, rest.input.databinding)
        const result = await OpenAIEngine.query(
          this.model.id,
          this.hash,
          rest.token,
          rest.assistant,
          message
        )
        if (result !== undefined) {
          if (rest.output.databinding) {
            JSONPath.set(this.modelValue, rest.output.databinding, result)
          }
          this.executeOutGoingLines(widget, true)
          return true
        }
      } catch (e) {
        console.error(e)
        Logger.error("Luisa.executeOpenAIAssistant", "error", e);
      }
      return false
    },

    async executePromptBuilder(widget) {
      Logger.log(-1, 'Luisa.executePromptBuilder() > enter', widget)
      const rest = widget.props.rest
      try {
        const prompt = rest.prompt
        const requiredDataBindings = RestEngine.getDataBindingVariables(prompt)
        const data = {}
        requiredDataBindings.forEach(path => {
          let value = JSONPath.get(this.modelValue, path)
          data[path] = value
        })

        const result = RestEngine.fillSimpleString(prompt, data)
        if (result !== undefined) {
          if (rest.output.databinding) {
            JSONPath.set(this.modelValue, rest.output.databinding, result)
          }
          this.executeOutGoingLines(widget, true)    
          return true
        }
      } catch (e) {
        console.error(e)
        Logger.error("Luisa.executePromptBuilder", "error", e);
      }
      return false
    },

    async executeLogic(widget, line) {
      Logger.log(1, 'Luisa.executeLogic() > enter', widget, line)

      let lines = ExportUtil.getLines(widget, this.model)
      let nextLine = null;

      if (widget.props && widget.props.isRandom) {
        const random = Math.random()
        const pos = Math.floor(random * lines.length);
        Logger.log(0, "Luisa.executeLogic", "enter >  do AB:" + widget.id + " >> " + random + " >> " + pos);
        nextLine = lines[pos]
      } else {
        nextLine = this.getRuleMatchingLine(lines)
      }

      if (nextLine) {
        this.executeLine(nextLine)
      } else {
        Logger.log(5, 'Luisa.executeLogic() > NO RULE matching', lines)
      }
    },

    async executeRest(widget) {
      Logger.log(-22, 'Luisa.executeRest() > enter', widget.props.rest)

      const rest = widget.props.rest

      /**
       * get all the data we need from the viewModel and pass
       * it as a simple flat map, e.g. {'user.name': 'peter'}
       */
      const requiredDataBindings = RestEngine.getNeededDataBings(rest)
      const data = {}
      requiredDataBindings.forEach(path => {
        let value = JSONPath.get(this.modelValue, path)
        data[path] = value
      })
      
      /**
       * Call rest
       */
      const success = await this.runRestEngine(rest, data)

      /**
       * Find next line to execute
       */
      const lines = ExportUtil.getLines(widget, this.model)
      const nextLine = this.getRuleMatchingLine(lines, success)
      if (nextLine) {
        this.executeLine(nextLine)
      } else {
        Logger.log(5, 'Luisa.executeRest() > NO RULE matching', lines)
      }
    },

    executeOutGoingLines (widget, success) {
      const lines = ExportUtil.getLines(widget, this.model)
        const nextLine = this.getRuleMatchingLine(lines, success)
        if (nextLine) {
          this.executeLine(nextLine)
        } else {
          Logger.log(5, 'Luisa.executeRest() > NO RULE matching', lines)
        }
    },

    async runRestEngine(rest, data) {
      Logger.log(-1, "Luisa.runRestEngine()", "enter");
      try {
        let result = await RestEngine.run(rest, data, this.hash, this.model.id)
        Logger.log(1, "Luisa.executeRest()", `set data "${rest.output.databinding}`, result);
        if (rest.output.path) {
          Logger.log(1, "Luisa.executeRest()", "path", rest.output.path);
          result = JSONPath.get(result, rest.output.path)
        }
        if (rest.output.databinding) {
          JSONPath.set(this.modelValue, rest.output.databinding, result)
        }
        return true
      } catch (e) {
        Logger.error("Luisa.executeRest", "error", e);
        if (rest.output.databinding) {
          JSONPath.set(this.modelValue, rest.output.databinding, 'Error')
        }
      }
      return false
    },

    getRuleMatchingLine(lines, restSuccess) {
    
      let matchedLine;
      for (var i = 0; i < lines.length; i++) {
        var line = lines[i];
        if (line.rule) {
          Logger.log(4, 'Luisa.getRuleMatchingLine() > check', i, line.rule)
          if (line.rule.type === 'widget') {
            Logger.error('Luisa.getRuleMatchingLine() > widget rules not supported in low code')
          }
          if (line.rule.type === 'databinding') {
            matchedLine = this.checkDataBindingRule(line)
          }
          if (line.rule.type === 'rest') {
            if (line.rule.restResponseStatus === '4xx' && !restSuccess) {
              matchedLine = line
            }
            if (line.rule.restResponseStatus === '200' && restSuccess) {
              matchedLine = line
            }
          }
          if (matchedLine) {
            break;
          }
        } else {
          /**
           * The *FIRST* line without a condition will be
           */
          if (!matchedLine) {
            matchedLine = line;
          }
        }
      }
      return matchedLine;
    },

    checkDataBindingRule(line) {
      Logger.log(4, 'Luisa.checkDataBindingRule() > enter', line.rule.databinding);
      const rule = line.rule
      const value = JSONPath.get(this.modelValue, rule.databinding)
      const result = this.isValueMatchingRule(value, true, rule);
      if (result) {
        Logger.log(-1, 'Luisa.checkDataBindingRule() > match!', line);
        return line
      }
    },

    isValueMatchingRule(value, valid, rule) {
      Logger.log(-44, 'Luisa.isValueMatchingRule() > enter > ' + rule.value + ' ' + rule.operator + ' >' + value + '< / ' + valid);

      const operator = rule.operator;
      /**
       * Special handling for checkbox group.
       * We should have an "in" operation
       */
 
      value = this.fixValueType(value)
      const ruleValue = this.fixValueType(rule.value)
      var result = false;
      switch (operator) {
        case "contains":
          if (value.toLowerCase && rule.value.toLowerCase) {
            var lowerValue = value.toLowerCase();
            var lowerRule = rule.value.toLowerCase();
            result = lowerValue.indexOf(lowerRule) >= 0;
          } else {
            result = false;
          }
          break;

        case "isValid":
          result = valid;
          break;

        case "==":    
          result = (value == ruleValue);
          break;

        case "!=":
          if (rule.value === null || rule.value === undefined) {
            result = value !== null && value !== undefined && value !== ''
          } else {
            result = (value != ruleValue);
          }
          break;

        case ">":
          if (!value) {
            value = 0;
          }
          result = (value * 1 > ruleValue * 1);
          break;

        case "<":
          if (!value) {
            value = 0;
          }
          result = (value * 1 < ruleValue * 1);
          break;

        case ">=":
          if (!value) {
            value = 0;
          }
          result = (value * 1 >= ruleValue * 1);
          break;

        case "<=":
          if (!value) {
            value = 0;
          }
          result = (value * 1 <= ruleValue * 1);
          break;

        default:
          Logger.warn('Luisa.isValueMatchingRule() Not supported operator')
      }
      return result;
    },

    fixValueType (value) {
      if (value && Array.isArray(value) && value.length > 0) {
        value = value[0]
        return value
      }
    
      if (value == 'true') {
        return true
      }
      if (value == 'false') {
        return false
      }
      if (value == undefined) {
        return false
      }
      return value
    }
  }
}
</script>
