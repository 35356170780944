<template>
    <div :class="'luisa-loading'">
        <img class="luisa-logo" src="/img/logo-long-w-s.png">
    
        
    </div>
</template>


<style lang="scss">
@import "../scss/login.scss";
@import "../scss/loading.scss";
</style>

<script>

export default {
    name: "404",
    mixins: [],
    props: [],
    data: function () {
        return {
            
        }
    },
    computed: {
    },
    watch: {
    },
    components: {
    },
    methods: {
        
    },
    async mounted() {
    }
}
</script>