import Logger from '../../core/Logger'

export default class ImageCarouselCSS {
    constructor(cssFactory) {
        Logger.log(5, 'ImageCarouselCSS.constructor()')
        this.cssFactory = cssFactory
    }

    run (selector, style, widget) {
        let result = ''
        result += selector + ' {\n'
        result += this.cssFactory.getPosition(widget);
        result += `  height: ${widget.h}px;\n`
        result += `  width: ${widget.w}px;\n`
        result += '}\n\n'
        result += selector + ' .qux-image-carousel-element {\n'
        result += `  width: ${widget.w}px;\n`
        result += `  height: ${widget.h}px;\n`
        result += '}\n\n'
        return result
    }
}