import Logger from '../../core/Logger'

export default class ChatCSS {

    constructor(cssFactory) {
        Logger.log(5, 'ChatCSS.constructor()')
        this.cssFactory = cssFactory
    }

    run (selector, style, widget) {
        let result = ''
        result += selector + ' {\n'
        result += this.cssFactory.getPosition(widget);
        result += this.cssFactory.getStyleByKey(style, widget, this.cssFactory.textProperties)
        result += `  gap: ${style.gap}px;\n`
        result += '}\n\n'

        result += selector + ' .qux-chat-input textarea{\n'
        result += this.cssFactory.getStyleByKey(style, widget, this.cssFactory.textProperties)
        result += `  background:${style.background};\n`
        result += `  color:${style.color};\n`
        result += this.cssFactory.getStyleByKey(style, widget, this.cssFactory.paddingProperties)
        result += this.cssFactory.getStyleByKey(style, widget, this.cssFactory.borderProperties)
        result += `  height: ${style.inputHeight}px;\n`
        result += '}\n\n'

        // const paddingTop = style._paddingTop ? (style._paddingTop / 2) : 0
        // const paddingRight = style._paddingRight ? (style._paddingRight) : 0
        // result += selector + ' .qux-chat-input .mdi{\n'
        // result += `  top: calc(50% - ${paddingTop}px);\n`
        // result += `  right: ${Math.max(0, 8 - paddingRight)}px;\n`
        // result += '}\n\n'

        if (widget.hover) {
            const hover = widget.hover
            result += selector + ' .qux-chat-input textarea:hover{\n'
            result += `  background:${hover.background};\n`
            result += `  color:${hover.color};\n`
            result += this.cssFactory.getStyleByKey(hover, widget, this.cssFactory.borderProperties)
            result += '}\n\n'
        }

        if (widget.focus) {
            const focus = widget.focus
            result += selector + ' .qux-chat-input textarea:focus{\n'
            result += `  background:${focus.background};\n`
            result += `  color:${focus.color};\n`
            result += this.cssFactory.getStyleByKey(focus, widget, this.cssFactory.borderProperties)
            result += '}\n\n'
        }



        result += selector + ' .qux-chat-cntr{\n'
        result += `  gap: ${style.gap}px;\n`
        result += '}\n\n'

        result += selector + ' .qux-chat-bubble{\n'
        result += this.cssFactory.getStyleByKey(style, widget, this.cssFactory.borderProperties)
        result += this.cssFactory.getStyleByKey(style, widget, this.cssFactory.paddingProperties)
        result += `  border-radius: ${style.bubbleBorderRadius}px;\n`
        result += `  border-width: ${style.borderWidth}px;\n`
        result += '}\n\n'

        result += selector + ' .user .qux-chat-bubble{\n'
        result += `  background:${style.userBackground};\n`
        result += `  color:${style.userColor};\n`
        result += `  border-color:${style.userBorderColor};\n`
        result += `  border-bottom-right-radius:0px;\n`
        result += '}\n\n'

        result += selector + ' .assistant .qux-chat-bubble{\n'
        result += `  background:${style.assistantBackground};\n`
        result += `  color:${style.assistantColor};\n`
        result += `  border-color:${style.assistantBorderColor};\n`
        result += `  border-bottom-left-radius:0px;\n`
        result += '}\n\n'
        

        // console.debug(result)
        return result
    }
}