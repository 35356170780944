<template>
  <div class="luisa-landing luisa-low-code-wrapper">
    <Loading v-if="currentView === 'loading'" />
    <Luisa :design="app" :config="config" v-model="viewModel" v-if="currentView === 'luisa'"/>
    <NotFound v-if="currentView === '404'" />
    <Login v-if="currentView === 'login'" @login="onUserLogin" />
  </div>
</template>
<style lang="scss">

</style>
<script>

import Services from '../services/Services'
import Logger from '../util/Logger'

import Login from './Login.vue'
import NotFound from './404.vue'
import Loading from './Loading'

export default {
  name: 'Home',
  data: function () {
    return {
      currentView: 'loading',
      design: '',
      app: false,
      responsive: null,
      defaultApp: '',
      debug: false,
      isQUX: true,
      actions: null,
      viewModel: {
          contact: {
            status: '',
            email: '',
            message: ''
          }          
      },
      config: {
        loadFonts: false,
        loadFontsWarning: false,
        responsive: [
          { page: "Desktop", types: ["desktop", ""] },
          { page: "Mobile", types: ["mobile"] },
        ],
        css: {
          attachLabels: false,
          huggedCanResize: true
        },
        figma: {
          downloadVectors: true,
        },
        router: {
          key: 'screenName',
          prefix: 'embedded'
        }
      }
    }
  },
  components: {
    Login, NotFound, Loading
  },
  methods: {
    onUserLogin () {
      this.loadApp()
    },
    async loadApp () {
      const modelService = Services.getModelService()

      const appName = this.$route.params.appName
      const orgName = this.$route.params.orgName
      Logger.log(-1, 'Home.loadApp()', orgName + ' > ' + appName)

      setTimeout( async () => {
        const pubsettings = await modelService.getPubSettings(orgName, appName)
        Logger.log(-1, 'Home.loadData()' , 'settings', pubsettings)
        if (pubsettings.token) {
          this.currentView = 'luisa'
          this.app = pubsettings.token
          this.config.router.prefix = `${orgName}/${appName}`
        } else if (pubsettings.error === 405) {
          this.currentView = 'login'
        } else {
          this.currentView = '404'
        }  
      }, 200)
   

    }
  },
  watch: {
    // $route() {
    //   this.logger.info("watch", "route");
    //   this.loadApp();
    // }
  },
  async mounted () {
    Logger.log(-1, 'Home.mounted()', location.hostname)

    this.loadApp()
  }
}
</script>
