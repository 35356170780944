import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Embedded from '../views/embedded/Embedded.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:orgName/:appName.html',
    name: 'Home2',
    component: Home
  },
  {
    path: '/:orgName/:appName/:screenName.html',
    name: 'Home3',
    component: Home
  },
  {
    path: '/embedded/:id.html',
    nane: 'Embedded',
    meta: {
      exlude: '/embedded',
    },
    component: Embedded
  },
  {
    path: '/embedded/:id/:screenName.html',
    nane: 'Embedded',
    component: Embedded
  }
]

const isHashRouter = false
const router = createRouter({
  history: isHashRouter ? createWebHashHistory () : createWebHistory(),
  routes
})

export default router
