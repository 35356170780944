import AbstractService from './AbstractService'

export class ModelService extends AbstractService{

    constructor () {
        super()
        this.errorAsJSON = true
    }


    getPubSettings (orgName, appName) {
        return this._get(`/rest/public/${orgName}/${appName}.json`)
    }

    
}
export default new ModelService()